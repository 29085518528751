var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"appPC"}},[_c('a-progress',{directives:[{name:"show",rawName:"v-show",value:(_vm.percent != 0),expression:"percent != 0"}],attrs:{"percent":_vm.percent,"showInfo":false}}),(_vm.spin)?_c('div',{staticClass:"loading"},[_c('a-spin',{attrs:{"size":"large"}})],1):_vm._e(),(
                !(
                    _vm.$route.name == 'MobileDetail' ||
                    _vm.$route.name == 'MobileBlankTemplate' ||
                    _vm.$route.name == 'MobileTerminal'
                )
            )?_c('Header'):_vm._e(),_c('div',{staticClass:"main"},[_c('router-view')],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }