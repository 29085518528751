<template>
    <div id="appPC">
        <a-progress :percent="percent" :showInfo="false" v-show="percent != 0" />
        <div class="loading" v-if="spin">
            <a-spin size="large"></a-spin>
        </div>
        <Header
            v-if="
                !(
                    $route.name == 'MobileDetail' ||
                    $route.name == 'MobileBlankTemplate' ||
                    $route.name == 'MobileTerminal'
                )
            "
        ></Header>
        <div class="main">
            <router-view />
        </div>
        <!-- <audio controls id="huanying" hidden="true">
            <source src="../../assets/audio/huanying.mp3" type="audio/mpeg" />
        </audio>
        <audio controls id="xuanze" hidden="true">
            <source src="../../assets/audio/xuanze.mp3" type="audio/mpeg" />
        </audio> -->
<!--        <Footer></Footer>-->
    </div>
</template>

<script>
    import Header from '@/components/PC/header/Header.vue'
    // import Footer from '@/components/PC/footer/Footer.vue'

    export default {
        name: 'app',
        computed: {
            percent() {
                return this.$store.state.percent
            },
            spin() {
                return this.$store.state.spin
            },
        },

        components: {
            Header,
            // Footer,
        },
    }
</script>

<style lang="scss" type="text/scss" scoped>
    #appPC {
        height: 100vh;
        width: 100vw;
        display: flex;
        flex-direction: column;
        background: url('../../assets/img/index/HomeBg.png') no-repeat;
        background-size: 100%;

        .main {
            flex: 1;
            overflow-x: hidden;
            overflow-y: scroll;
        }
    }

    .main::-webkit-scrollbar {
        display: none;
    }

    .loading {
        width: 100vw;
        height: 100vh;
        background-color: rgba(255, 255, 255, 0.2);
        position: fixed;
        top: 0;
        left: 0;
        z-index: 100000;
        display: flex;
        align-items: center;
        justify-content: center;
    }
</style>
